<script>
import Chart from "@/components/widgets/chart";
import { api } from "@/state/services";

export default {
  components: { Chart },
  props: {
    id: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      empty: true,
      chartdata: { },
      options: { }
    }
  },
  methods: {
    getChart() {
      api
        .get('contract/' + this.id + '/chart')
        .then(response => {
          if (response.data.status=='success') {
            this.chartdata = {
              labels: response.data.labels,
              datasets: [
                {
                  label: response.data.contract.label,
                  fill: true,
                  backgroundColor: 'transparent',
                  borderWidth: 2,
                  borderColor: '#00A859',
                  borderCapStyle: 'butt',
                  borderDash: [],
                  borderDashOffset: 0,
                  borderJoinStyle: 'miter',
                  pointBorderColor: '#090a09',
                  pointBackgroundColor: '#fff',
                  pointBorderWidth: 0,
                  pointHoverRadius: 0,
                  pointHoverBackgroundColor: '#090a09',
                  pointHoverBorderColor: '#090a09',
                  pointHoverBorderWidth: 0,
                  pointRadius: 0,
                  pointHitRadius: 10,
                  data: response.data.contract.data,
                },
                {
                  label: response.data.ibovespa.label,
                  fill: true,
                  backgroundColor: 'transparent',
                  borderWidth: 2,
                  borderColor: '#f71d49',
                  borderCapStyle: 'butt',
                  borderDash: [],
                  borderDashOffset: 0,
                  borderJoinStyle: 'miter',
                  pointBorderColor: '#090a09',
                  pointBackgroundColor: '#fff',
                  pointBorderWidth: 0,
                  pointHoverRadius: 0,
                  pointHoverBackgroundColor: '#090a09',
                  pointHoverBorderColor: '#090a09',
                  pointHoverBorderWidth: 0,
                  pointRadius: 0,
                  pointHitRadius: 10,
                  data: response.data.ibovespa.data,
                }
              ]
            }
            this.options = {
              defaultFontColor: '#090a09',
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      color: 'rgba(235, 28, 36, 0.1)',
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      max: 100,
                      min: -100,
                      stepSize: 10,
                    },
                    gridLines: {
                      color: 'rgba(235, 28, 36, 0.1)',
                    },
                  },
                ],
              },
            }

            this.empty = false
          } else {
            this.empty = true
          }
        })
    }
  },
  mounted() {
    this.getChart();
  },
}
</script>

<template>
  <div v-if="empty" class="text-center">
  </div>
  <div v-else>
    <Chart :chartdata="chartdata" :options="options"></Chart>
  </div>
</template>